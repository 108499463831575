import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs, { init } from '@emailjs/browser';
// EMAIL
export default function Contact() {
  const [invalidUser, setInvalidUser] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  const handleName = (e) => {
    console.log(e.target.value);
    setName(e.target.value);
    setForm({
      name: name,
      email: email,
      message: msg,
    });
  }
  const handleEmail = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
    setForm({
      name: name,
      email: email,
      message: msg,
    });
  }
  const handleMsg = (e) => {
    console.log(e.target.value);
    setMsg(e.target.value);
    setForm({
      name: name,
      email: email,
      message: msg,
    });
  }
  const [form, setForm] = useState({
    name: name,
    email: email,
    message: msg,
  });
  const captcha = useRef(null);

  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setInvalidUser(false);
    }
  };
  // 5GqYP2s1z943NQQEY
  init("5GqYP2s1z943NQQEY");

  // template_8xs8kbe
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.send(`service_kzyrjw2`, `template_8xs8kbe`, form, `5GqYP2s1z943NQQEY`)
    .then((result) => {
    alert("Message Sent, We will get back to you shortly", result.text);
    },
    (error) => {
    alert("An error occurred, Please try again", error.text);
    });
  };

  return (
    <div className="md:ml-0 md:mr-10 py-10 pb-[100px] px-[25px] md:px-[60px]">
      <h2 className="text-[50px] text-[#62a401] mb-6 text-center">
        Contact Us
      </h2>
      <form id='form' className="flex flex-col gap-8" onSubmit={(e) => handleSubmit(e)}>
        <input
          name="name"
          className="px-8 py-4 rounded-[12px] focus:outline-none focus:ring-4 focus:ring-[#62a401]"
          type="text"
          placeholder="Your Name"
          onChange={(e) => handleName(e)}
        />
        <input
          name="email"
          className="px-8 py-4 rounded-[12px] focus:outline-none focus:ring-4 focus:ring-[#62a401]"
          type="text"
          placeholder="Your Email"
          required
          onChange={(e) => handleEmail(e)}

        />
        <textarea
          name="message"
          className="px-8 py-5 rounded-[12px] focus:outline-none focus:ring-4 focus:ring-[#62a401] h-[150px]"
          type="text"
          placeholder="Your Message"
          required
          onChange={(e) => handleMsg(e)}

        />
        <div>
          <ReCAPTCHA
            ref={captcha}
            sitekey="6Lct9MgeAAAAAGJlsCwoNndqv6cyAO4bEFweyy72"
            onChange={() => handleCaptcha()}
          />
        </div>
        <button
          disabled={invalidUser}
          className="px-8 py-4 rounded-[12px] text-white font-bold bg-[#62a401] hover:bg-white hover:ring-4 hover:ring-[#62a401] hover:text-[#62a401] transition ease-in-out duration-150 disabled:opacity-30"
        >
          Send Message
        </button>
      </form>
    </div>
  );
}
