import React from 'react';
import { BsInstagram, BsFacebook, BsTwitter, BsGridFill } from 'react-icons/bs';

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-4 md:px-16">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-white"
              href="/"
            >
              <img
                className="w-[300px]"
                src="/images/white-letter-logo.png"
                alt="00RE Logo"
              />
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 border border-solid border-transparent rounded block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <BsGridFill />
            </button>
          </div>
          <div
            className={`lg:flex flex-grow items-center${
              navbarOpen ? ' flex' : ' hidden'
            }`}
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto mx-auto md:mx-0">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  href="https://www.instagram.com/00re_official/"
                  target="_blank"
                >
                  <BsInstagram className="fab text-lg leading-lg text-[#49862e] md:text-white" />
                  <span className="ml-2 text-[#49862e] md:text-white">
                    @00re_official
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  href="https://www.facebook.com/official00re"
                  target='_blank'
                >
                  <BsFacebook className="fab text-lg leading-lg text-[#49862e] md:text-white" />
                  <span className="ml-2 text-[#49862e] md:text-white">
                    @official00re
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs  font-bold leading-snug text-white hover:opacity-75"
                  href="https://twitter.com/00re_official"
                  target="_blank"
                >
                  <BsTwitter className="fab text-lg leading-lg text-[#49862e] md:text-white" />
                  <span className="ml-2 text-[#49862e] md:text-white">
                    @00re_official
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
