import React from 'react';
import Contact from '../Contact/Contact';
import '../../index.css';

export default function Content() {
  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-2 p-0 mt-[-100vh] md:mt-[-70vh] 2xl:mx-[10vw]">
        <div className="flex flex-col text-center items-center justify-center md:px-16 order-1">
          <img
            src="/images/kid-with-plant.png"
            alt="Kid with Plant"
            className="w-[80vw] md:w-[30vw]"
          />
          <h1 className="mt-6 mb-10 text-7xl md:text-[100px] text-[#62a401] leading-[75px] uppercase">
            Think <br />
            <span className="text-7xl md:text-[90px] text-[#62a401] font-bold">
              Green
            </span>
          </h1>
        </div>
        <div className="order-2"></div>
        <div className="order-3"></div>
        <div className="md:mt-[-20vh] px-5 md:px-0 order-4">
          <div className="flex items-center justify-center">
            <img src="/images/tree-icon.png" alt="" />
            <p className="ml-10 text-md text-[#62a401] font-bold font-Rosmatika w-[350px]">
              00RE has made its mission to preserve nature and wildlife in its
              purest form possible.
            </p>
          </div>
          <div className="flex items-center my-10 justify-center">
            <img src="/images/house-icon.png" alt="" />
            <p className="ml-10 text-md text-[#62a401] font-bold  font-Rosmatika w-[350px]">
              00RE is proudly supporting the goals of sustainable living and
              business operations for all.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <img src="/images/recycle-icon.png" alt="" />
            <p className="ml-10 text-md text-[#62a401] font-bold  font-Rosmatika w-[350px]">
              00RE applying an innovative approach to utilizing natural
              resources to restore and protect environment.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-end order-6 md:order-5">
          <img
            src="/images/women-cascade.png"
            alt="Women with cascade"
            className="w-[95vw] ml-[-20px] md:ml-0 md:w-[40vw]"
          />
        </div>
        <div className="order-5 md:order-6">
          <Contact />
        </div>
      </div>
    </>
  );
}
